import { ISinglePageCheckout } from '~/components/templates/Checkout/SinglePageCheckout/hooks'
import { removeMask } from '~/utils/removeMask'

export const parseUserDataFromParams = (userDataQueryParams: string | string[]): Partial<ISinglePageCheckout> => {
  if (!userDataQueryParams || typeof userDataQueryParams !== 'string') {
    return {}
  }

  try {
    const data = JSON.parse(userDataQueryParams as string)

    const noMaskData = {
      ...data,
      CPF: removeMask(data.CPF),
      phone: removeMask(data.phone),
      birthDate: removeMask(data.birthdate),
      zipcode: removeMask(data.zipcode),
    }

    return noMaskData
  } catch (error) {
    console.error('Error parsing userData:', error)
  }

  return {}
}
