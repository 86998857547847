import { useRouter } from 'next/router'
import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react'
import useSWR, { KeyedMutator } from 'swr'
import { ICartData, IGetCart, IPartnerData, IPaymentForms, IQueryParams } from 'types'
import { IProductType, productTypes } from '~/constants/productTypes'
import { CoursesContext } from '~/contexts/CoursesContext'
import { LocalStorageKeys } from '~/enums/localStorageKeys'
import { useIFrameParams } from '~/hooks/useIFrameParams'
import { useUserData } from '~/hooks/useUserData'
import { getCart, getPartner, updateCart } from '~/services/api'
import { buildURLQuery } from '~/utils/buildURLQuery'
import { changeRouteKeepParams } from '~/utils/changeRouteKeepParams'
import { isSameArray } from '~/utils/isSameArray'
import { parseUserDataFromParams } from '~/utils/parseUserDataFromParams'
import useSessionStorage from '../hooks/useSessionStorage'

interface IParamsContextData {
  router: ReturnType<typeof useRouter>
  route: string
  partnerSlug: string
  getParams: () => IQueryParams
  cartData: ICartData | null
  isCartLoading: boolean
  mutateCart: KeyedMutator<ICartData>
  productType: IProductType
  partnerData: IPartnerData | null
  isPartnerLoading: boolean
}

interface ParamsProviderProps {
  children: ReactNode
}

export const ParamsContext = createContext({} as IParamsContextData)

export function ParamsProvider({ children }: ParamsProviderProps) {
  const { getParams, selectedClasses, setSelectedClasses } = useContext(CoursesContext)
  const { iframeParams, setIframeParams } = useIFrameParams()
  const { setUserData } = useUserData()
  const [chosenPaymentMethod] = useSessionStorage<IPaymentForms | null>('chosenPaymentMethod', null)
  const router = useRouter()
  const { route } = router
  const params = getParams()
  const urlParams = router.query

  const productType: IProductType = useMemo(() => {
    if (typeof window === 'undefined') {
      return 'ProviPay'
    }

    const [subdomain] = window.location.hostname.split('.')
    return productTypes[subdomain] || 'ProviPay'
  }, [])

  const partnerSlug = (router.query.slug as string) || ''
  const routesWithoutFetch = ['/', '/courses', '/checkout', '/login', '/login/[slug]', '/fatura/[slug]'].includes(route)

  let orderedParams: IGetCart['params'] = {
    ...params,
    classes: params.classes
      ?.split(',')
      ?.map(Number)
      ?.sort((a, b) => a - b)
      ?.toString(),
  }

  const cartFetcher = async (url: string) => {
    if (!Object.keys(params || {}) || routesWithoutFetch) {
      return null
    }

    if (url == '/cart/') {
      orderedParams = {
        ...orderedParams,
        classes: null,
      }
    }

    const hasUserToken = sessionStorage.getItem(LocalStorageKeys.user_token)

    const newCourses = orderedParams?.classes?.split(',')?.map((course) => Number(course)) || []

    if (hasUserToken && route === '/courses/[slug]') {
      try {
        const updatedCart = await updateCart(newCourses)

        if (typeof updatedCart !== 'string') {
          return updatedCart.cart
        }
      } catch (err) {
        return getCart({ partnerSlug, params: orderedParams }).then((data) => data)
      }
    }

    return getCart({ partnerSlug, params: orderedParams }).then((data) => data)
  }

  const {
    data: cartData,
    mutate,
    isValidating: isCartLoading,
  } = useSWR<ICartData | null>(`/cart/${orderedParams?.classes || ''}`, cartFetcher, {
    dedupingInterval: 1000,
    revalidateOnFocus: false,
    onSuccess: (data) => {
      // this redirects to /courses if cart has 'selectClass' flag set to true
      if (data?.selectClass === true && route === '/checkout/[slug]') {
        const url = changeRouteKeepParams(router.asPath, `/courses/${partnerSlug}`)
        // we have to wait 100ms so router can update the url
        setTimeout(() => router.push(url), 100)
      }
    },
  })

  const partnerFetcher = async () => {
    if (partnerSlug && route !== '/fatura/[slug]') {
      return getPartner(partnerSlug).then((data) => data)
    }
  }

  const { data: partnerData, isValidating: isPartnerLoading } = useSWR<IPartnerData | null>(partnerSlug, partnerFetcher, {
    dedupingInterval: 10000,
    revalidateOnFocus: false,
  })

  useEffect(() => {
    const redirectUrl = urlParams?.redirectUrl?.toString()
    const support = urlParams?.support
    const hideBoletoAsUpfront = urlParams?.hideBoletoAsUpfront
    const userData = parseUserDataFromParams(urlParams?.userData)

    setIframeParams({
      ...iframeParams,
      ...(redirectUrl && { redirectUrl }),
      ...(support && { support: support === 'true' }),
      ...(hideBoletoAsUpfront && { hideBoletoAsUpfront: hideBoletoAsUpfront === 'true' }),
    })

    if (Object.keys(userData).length) {
      setUserData(userData)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    mutate()
  }, [chosenPaymentMethod])

  // update selectedClasses and url query params when cartData changes
  useEffect(() => {
    if (cartData) {
      const { courses } = cartData

      // update selectedClasses
      if (courses?.length) {
        const classIds = courses.map((course) => course.CourseClassId)

        if (isSameArray(classIds, params.classes?.split(',').map(Number))) {
          setSelectedClasses(
            courses.map((course) => ({
              classId: course.CourseClassId,
              courseId: course.CourseId,
              courseClassName: course.className,
              price: Number(course.price),
            })),
          )
        }
      } else {
        setSelectedClasses([])
      }

      // update url query params
      const newParams = {
        ...params,
        classes: courses.map((course) => course.CourseClassId).join(','),
      }

      const query = buildURLQuery(newParams)
      const newUrl = `${route.replace('[slug]', partnerSlug)}${query}`
      router.push(newUrl, newUrl, { shallow: true })

      return
    }

    if (!selectedClasses?.length) {
      return
    }

    // update url query params regardless if cart returned empty
    const defaultParams = {
      ...params,
      classes: selectedClasses?.map((course) => course.classId).join(','),
    }

    const query = buildURLQuery(defaultParams)
    const newUrl = `${route.replace('[slug]', partnerSlug)}${query}`
    router.push(newUrl, newUrl, { shallow: true })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData, router.asPath])

  return (
    <ParamsContext.Provider
      value={{
        router,
        route,
        partnerSlug,
        getParams,
        cartData,
        isCartLoading,
        mutateCart: mutate,
        productType,
        partnerData,
        isPartnerLoading,
      }}
    >
      {children}
    </ParamsContext.Provider>
  )
}
