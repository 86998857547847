import { useCallback, useContext, useEffect } from 'react'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { api, creditPathApi } from '~/services/config'
import { LocalStorageKeys } from '~/enums/index'
import { CheckoutContext } from './CheckoutContext'
import { errorHandler } from '~/utils/errorHandler'
import { generateToken } from '~/utils/generateToken'
import { ParamsContext } from './ParamsContext'
import { useAnalytics } from '~/hooks/useAnalytics'
import { useRouter } from 'next/router'
import { changeRouteKeepParams } from '~/utils/changeRouteKeepParams'

const Interceptors = () => {
  const { setProgressBar, setIsLoadingData, setIsSendingData, setStep, setIsRedirecting, step } = useContext(CheckoutContext)
  const { mutateCart, productType, partnerSlug } = useContext(ParamsContext)
  useAnalytics()
  const router = useRouter()

  const proviPayRoutes = ['/courses/[slug]', '/checkout/[slug]', '/courses', '/checkout']
  const isaRoutes = ['/login', '/login/[slug]']

  useEffect(() => {
    if (productType === 'ISA' && proviPayRoutes.includes(router.pathname)) {
      router.replace(changeRouteKeepParams(router.asPath, `/login/${partnerSlug}`))
    }

    if (productType === 'ProviPay' && isaRoutes.includes(router.pathname)) {
      router.replace(changeRouteKeepParams(router.asPath, `/courses/${partnerSlug}`))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isaRoutes, partnerSlug, productType, proviPayRoutes])

  const cancelAllLoadingStates = useCallback(() => {
    if (typeof window !== 'undefined') {
      setIsLoadingData(false)
      setIsSendingData(false)
    }
  }, [setIsLoadingData, setIsSendingData])

  const handleLoadingRequests = useCallback(
    (config: AxiosRequestConfig) => {
      if (typeof window !== 'undefined') {
        if (config.method === 'get') {
          // don't setLoading if url has either /courses-and-classes/ or /cart/ in it
          // /courses-and-classes/ shouldn't be loading on infinite-scroll
          // /cart/ shouldn't be loading here because it's already loading in ParamsContext with swr
          const noLoadingPaths = ['/courses-and-classes/', '/cart/']
          if (noLoadingPaths.some((path) => config.url.includes(path))) {
            return
          }

          setIsLoadingData(true)
        }

        if (config.method === 'post') {
          setIsSendingData(true)
        }
      }
    },
    [setIsLoadingData, setIsSendingData],
  )

  const updateCart = useCallback(
    (response: AxiosResponse) => {
      if (typeof window !== 'undefined' && response.data.refreshCart === true) {
        mutateCart()
      }
    },
    [mutateCart],
  )

  const updateProgressBar = useCallback(
    (response: AxiosResponse) => {
      if (response.data.progress) {
        setProgressBar(response.data.progress)
      }
    },
    [setProgressBar],
  )

  const handleNextScreen = useCallback(
    (response: AxiosResponse) => {
      if (
        !response?.data?.error &&
        step === 'loadingFinanceSimulation' &&
        response.data.nextScreen !== 'loadingFinanceSimulation'
      ) {
        return
      }

      if (response.data?.nextScreen) {
        // if we get a nextScreen, we automatically go to the next screen
        setStep(response.data.nextScreen)
      }
    },
    [setStep, step],
  )

  const handleSaveToken = useCallback((response: AxiosResponse) => {
    if (response.data?.token) {
      // if we get a token, we automatically save it in localStorage
      sessionStorage.setItem(LocalStorageKeys.user_token, response.data.token)
    }
  }, [])

  const handleRedirect = useCallback(
    (response: AxiosResponse) => {
      if (response.data?.redirect && response.data?.accessUrl) {
        // if we get a redirect, we automatically redirect to the accessUrl in the browser
        setIsRedirecting(true)
        window.location.href = response.data.accessUrl
      }
    },
    [setIsRedirecting],
  )

  useEffect(() => {
    creditPathApi.interceptors.response.use(
      (response) => {
        cancelAllLoadingStates()
        handleSaveToken(response)
        updateCart(response)

        updateProgressBar(response)
        handleNextScreen(response)
        handleRedirect(response)

        return response
      },
      async (error) => {
        cancelAllLoadingStates()
        handleNextScreen(error?.response || {})
        return errorHandler(error?.response?.data)
      },
    )

    creditPathApi.interceptors.request.use(
      (config) => {
        handleLoadingRequests(config)

        const token = sessionStorage.getItem(LocalStorageKeys.user_token)

        if (token) {
          config.headers.Authorization = token
        }

        return config
      },
      (error) => {
        cancelAllLoadingStates()
        return errorHandler(error.response.data)
      },
    )

    api.interceptors.response.use(
      (response) => {
        cancelAllLoadingStates()

        handleSaveToken(response)
        updateCart(response)
        handleNextScreen(response)

        return response
      },
      (error) => {
        cancelAllLoadingStates()
        handleNextScreen(error?.response || {})

        const status = error?.response ? error?.response?.status : null

        if (status === 401) {
          const newToken = generateToken()
          error.config.headers.Authorization = newToken
          localStorage.setItem(LocalStorageKeys.token, newToken)
          return axios.request(error.config)
        }

        return errorHandler(error.response.data)
      },
    )

    api.interceptors.request.use(
      (config) => {
        handleLoadingRequests(config)

        const token = generateToken()
        if (token) {
          config.headers.Authorization = token
        }

        return config
      },
      (error) => {
        cancelAllLoadingStates()
        return errorHandler(error.response.data)
      },
    )

    // cleanup all interceptors because we don't want to have multiple interceptor handlers (this would be a memory leak)
    return () => {
      // @ts-ignore
      ;(creditPathApi.interceptors.request.handlers = []),
        // @ts-ignore
        (creditPathApi.interceptors.response.handlers = []),
        // @ts-ignore
        (api.interceptors.request.handlers = []),
        // @ts-ignore
        (api.interceptors.response.handlers = [])
    }
  }, [
    cancelAllLoadingStates,
    handleLoadingRequests,
    handleNextScreen,
    handleSaveToken,
    updateCart,
    updateProgressBar,
    handleRedirect,
  ])

  return null
}

export default Interceptors
