export const masks = {
  cpf: '999.999.999-99',
  CPF: '999.999.999-99',
  boleto: '99999.99999 99999.999999 99999.999999 9 99999999999999',
  phone: '(99) 99999-9999',
  creditCard: '9999 9999 9999 9999',
  expireDate: '99/99',
  cardCVV: '9999',
  cep: '99999-999',
  zipcode: '99999-999',
  birthDate: '99/99/9999',
  rg: '99999999-*',
}
