/**
 * @name unicoLayout
 * @description object for unico sdk configuration. We can set colors and divs here
 */
export const unicoLayout = {
  silhouette: {
    primaryColor: '#006eab',
    secondaryColor: '#DE0C4B',
    neutralColor: '#FFFFFF',
  },
  buttonCapture: {
    backgroundColor: '#FFFFFF',
    iconColor: '#006eab',
  },
  popupLoadingHtml:
    '<div style="position: absolute; top: 45%; right: 50%; transform: translate(50%, -50%); z-index: 10; text-align: center;">Carregando sua câmera...</div>',
  popupOrientationHtml:
    '<div style="position: absolute; top: 45%; right: 50%; transform: translate(50%, -50%); z-index: 10; text-align: center;">Mantenha o celular no modo porta retrato</div>',
  boxMessage: {
    backgroundColor: '#FFFFFF',
    fontColor: '#000000',
  },
  boxDocument: {
    backgroundColor: '#006eab',
    fontColor: '#FFFFFF',
  },
}

export const unicoConfig = { TYPE: 2 } //that means we are using unico smartcamera
