import { toast } from 'react-toastify'
import { IError } from '../types'

export const errorHandler = (error: IError): Promise<string> => {
  if (typeof window === 'undefined') {
    return Promise.reject(error)
  }

  if (typeof window !== 'undefined' && window.location.pathname.includes('/fatura/')) {
    return Promise.reject(error)
  }

  // don't show toast if 'PATH-01' or 'PATH-08' is in the string
  const noToastErrors = ['PATH-01', 'PATH-08']
  const screen = sessionStorage.getItem('step') || ''

  if (typeof error === 'string') {
    if (noToastErrors.some((errorCode) => error.includes(errorCode))) {
      return Promise.resolve(error)
    }

    error !== 'Invalid token' &&
      toast.error(error, {
        toastId: 'errorHandler',
      })
    return Promise.reject(error)
  }

  error?.errors?.forEach((singleError) => {
    console.log(singleError)
    if (screen.includes('singlePageCheckout') && singleError.message.includes('PATH-13')) {
      return Promise.resolve(error)
    }

    if (noToastErrors.some((errorCode) => singleError.message.includes(errorCode))) {
      return
    }

    toast.error(singleError.message, {
      toastId: singleError.message,
    })
  })

  // we return only the first error because we need it to return a string
  // we return a string because we're handling errors like this: `typeof result !== 'string' ? setCorrectState(result) : setErrorState(result)`
  // basically correctState is an object, errorState is a string
  return Promise.reject(error?.errors[0]?.message || 'Algo deu errado na requisição')
}
