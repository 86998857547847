import { createContext, ReactNode, useState, Dispatch, SetStateAction, useCallback, useContext } from 'react'
import { toast } from 'react-toastify'
import { IStep } from 'types'
import { CallbackCamera } from 'unico-webframe'
import { unicoLayout } from '~/components/templates/Checkout/unicoSelfieScreen/constants'
import { CheckoutContext } from '~/contexts/CheckoutContext'

type IDocumentType = 'upload' | 'webcam' | null

interface IOpenFilePicker {
  setIsSuccessful?: Dispatch<SetStateAction<boolean>>
  setNextScreen?: Dispatch<SetStateAction<IStep | null>>
  onSuccess?: () => void
}

interface IInput extends HTMLInputElement {
  capture: VideoFacingModeEnum
}

interface CreditPathProviderProps {
  children: ReactNode
}

interface ICreditPathContextData {
  isWebcamOpen: boolean
  setIsWebcamOpen: Dispatch<SetStateAction<boolean>>
  documentImage: string | null
  setDocumentImage: Dispatch<SetStateAction<string | null>>
  documentType: IDocumentType
  setDocumentType: Dispatch<SetStateAction<IDocumentType>>
  openFilePicker: ({ setIsSuccessful, setNextScreen }: IOpenFilePicker) => void
  shouldMockPNG: boolean
  unicoFailed: boolean
  initUnico: (nextScreen?: IStep) => void
  isSuccessful: boolean
  setIsSuccessful: Dispatch<SetStateAction<boolean>>
}

export const CreditPathContext = createContext({} as ICreditPathContextData)

export const CreditPathProvider = ({ children }: CreditPathProviderProps) => {
  const { step } = useContext(CheckoutContext)

  const [isWebcamOpen, setIsWebcamOpen] = useState(false)
  const [documentImage, setDocumentImage] = useState<string | null>(null)
  const [documentType, setDocumentType] = useState<IDocumentType>(null)
  const [shouldMockPNG, setShouldMockPNG] = useState<boolean>(false)
  const [unicoFailed, setUnicoFailed] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false)

  const openFilePicker = useCallback(
    ({ setIsSuccessful, setNextScreen, onSuccess }: IOpenFilePicker) => {
      setDocumentType('upload')
      setIsWebcamOpen(false)
      setDocumentImage(null)
      setShouldMockPNG(false)
      setIsSuccessful && setIsSuccessful(false)
      setNextScreen && setNextScreen(null)

      const input = document.createElement('input') as IInput
      input.type = 'file'
      input.accept = '.png,.jpg,.jpeg'
      input.capture = 'environment'
      input.click()

      input.onchange = async () => {
        const file = input.files && input.files[0]

        if (file) {
          // mocks file type to png in staging env so document can go to analysis
          if (file.name.endsWith('.png') && process.env.NEXT_PUBLIC_API_ENV === 'staging') {
            setShouldMockPNG(true)
          }

          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => {
            // convert image to jpeg
            const image = new Image()
            image.src = reader.result as string
            image.onload = () => {
              const canvas = document.createElement('canvas')
              canvas.width = image.width
              canvas.height = image.height
              canvas.getContext('2d')?.drawImage(image, 0, 0)
              const dataURL = canvas.toDataURL('image/jpeg')
              setDocumentImage(dataURL)
              onSuccess && onSuccess()
            }
          }
        }
      }
    },
    [setDocumentType, setDocumentImage, setIsWebcamOpen, setShouldMockPNG],
  )

  /**
   * @name initUnico
   * @description function to open camera and capture document image
   */
  const initUnico = async (nextScreen?: IStep) => {
    setIsWebcamOpen(true)
    const callback: CallbackCamera = {
      on: {
        success: function (obj) {
          const hasBase64Headers = obj?.base64?.includes('data:image/jpeg;base64,')
          const base64 = hasBase64Headers ? obj.base64 : `data:image/jpeg;base64,${obj.base64}`

          setDocumentImage(base64)
          setIsSuccessful(false)
          setIsWebcamOpen(false)
        },
        error: function (err) {
          console.log({ err })

          if (err?.type === 'SessionError' || err?.type === 'LifecicleError') {
            // restart unico because of timeout
            initUnico()
            return
          }

          setUnicoFailed(true)
          setTimeout(() => {
            setIsWebcamOpen(true)
          }, 1000)

          toast.error(err.message || err, {
            toastId: 'initUnico',
            autoClose: 10000,
          })
        },
        support: function (err) {
          console.log('support: ', err)
          setUnicoFailed(true)
          setTimeout(() => {
            setIsWebcamOpen(true)
          }, 1000)
        },
      },
    }

    const acessoWebFrame = await import(
      // @ts-ignore
      'unico-webframe-legacy/dist/unico-webframe.esm'
    )

    const unicoSetup = {
      insertCNH: { config: { TYPE: 1 } },
      insertRGFront: { config: { TYPE: 6 } },
      insertRGBack: { config: { TYPE: 7 } },
    }

    const setup = unicoSetup[nextScreen || step]

    return acessoWebFrame.initDocument(setup.config, callback, unicoLayout)
  }

  return (
    <CreditPathContext.Provider
      value={{
        isWebcamOpen,
        setIsWebcamOpen,
        documentImage,
        setDocumentImage,
        documentType,
        setDocumentType,
        openFilePicker,
        shouldMockPNG,
        unicoFailed,
        initUnico,
        isSuccessful,
        setIsSuccessful,
      }}
    >
      {children}
    </CreditPathContext.Provider>
  )
}
