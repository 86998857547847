import { theme } from '@provi/provi-components'
import '@provi/provi-components/lib/index.css'
import styled, { createGlobalStyle, css, keyframes } from 'styled-components'

const GlobalStyles = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body,
  #__next {
    height: 100%;
  }

  body {
    max-width: 100vw;
    margin: 0 auto;
    background-color: ${theme.colors.bluePrimaryLight};
    font-family: Montserrat, sans-serif;
    overflow-y: scroll;
  }

  img {
    image-rendering: -webkit-optimize-contrast;
  }

  sup {
    font-size: 0.7em;
    line-height: 1em;
    position: relative;
    vertical-align: revert;
    top: revert;
  }

  .grecaptcha-badge {
    display: none !important;
  }

  /* tailwind adds a border-box that breaks provi_components checkbox */
  label[data-testid="test-label"] > span::after {
    box-sizing: content-box;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  
  input[data-autocompleted] {
    background-color: transparent !important;
  }
`

export default GlobalStyles

export const H1 = styled.h1`
  font-weight: 500;
  font-size: 48.83px;
  line-height: 60px;
  letter-spacing: -0.0025em;

  ${theme.breakpoints.down('md')} {
    font-size: 32.85px;
    line-height: 48px;
    letter-spacing: 0em;
  }
`

export const H2 = styled.h2`
  font-weight: 500;
  font-size: 39.06px;
  line-height: 48px;
  letter-spacing: 0em;

  ${theme.breakpoints.down('md')} {
    font-size: 27.37px;
    line-height: 40px;
    letter-spacing: 0.0025em;
  }
`

export const H3 = styled.h3`
  font-weight: 500;
  font-size: 31.25px;
  line-height: 40px;
  letter-spacing: 0em;

  ${theme.breakpoints.down('md')} {
    font-size: 22.81px;
    line-height: 36px;
    letter-spacing: 0.005em;
  }
`

export const H4 = styled.h4`
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 0.0025em;

  ${theme.breakpoints.down('md')} {
    font-size: 19.1px;
    line-height: 28px;
    letter-spacing: 0.005em;
  }
`

export const Body1 = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.005em;

  ${theme.breakpoints.down('md')} {
    font-size: 15.84px;
    line-height: 24px;
  }
`

export const Body2 = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;

  ${theme.breakpoints.down('md')} {
    font-size: 13.2px;
    line-height: 20px;
    letter-spacing: 0.005em;
  }
`

export const Overline = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.015em;
  text-transform: uppercase;

  ${theme.breakpoints.down('md')} {
    font-size: 13.2px;
    line-height: 20px;
    letter-spacing: 0.02em;
  }
`

export const Link = styled.a`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-decoration: underline;
  color: ${theme.colors.black100};

  ${theme.breakpoints.down('md')} {
    font-size: 13.2px;
    line-height: 20px;
  }
`

export const Caption = styled.span`
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;

  ${theme.breakpoints.down('md')} {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.0075em;
  }
`

export const Button = styled.button`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: ${theme.colors.bluePrimary};

  background: transparent;
  border: none;
  cursor: pointer;
  width: fit-content;
  transition: color 0.2s ease-in-out;

  :hover {
    color: ${theme.colors.blueSecundary};
  }

  :active {
    color: ${theme.colors.blueSecundaryDark};
  }

  :focus,
  :focus-within {
    outline-color: ${theme.colors.bluePrimary};
    outline-offset: 4px;
    color: ${theme.colors.blueSecundary};
  }

  ${theme.breakpoints.down('md')} {
    font-size: 13.2px;
    line-height: 20px;
  }
`

export const loadingAnimation = keyframes`
    0%{background-position: 0% 0%}
    50%{background-position: 100% 0%}
    100%{background-position: 0% 0%}
`

export const loadingCSS = css`
  background: ${css`linear-gradient(270deg, ${theme.colors.bluePrimaryLight}, ${theme.colors.white100})`};
  background-size: 200%;

  -webkit-animation: ${loadingAnimation} 1s ease infinite;
  -moz-animation: ${loadingAnimation} 1s ease infinite;
  animation: ${loadingAnimation} 1s ease infinite;
  animation: ${loadingAnimation} 1s ease infinite;
`

export const SpinnerLoading = styled.div`
  display: inline-block;
  width: fit-content;
  height: fit-content;

  ::after {
    content: ' ';
    display: block;
    width: 72px;
    height: 72px;
    margin: 8px;
    border-radius: 50%;
    border: 8px solid ${theme.colors.bluePrimary};
    border-color: ${`${theme.colors.bluePrimary} ${theme.colors.bluePrimaryLight} ${theme.colors.bluePrimaryLight}`};
    animation: lds-ring 2s linear infinite;

    ${theme.breakpoints.down('md')} {
      width: 64px;
      height: 64px;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
