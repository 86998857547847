/**
 * @description Check if two arrays are equal regardless of order
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSameArray = (arr1: any[], arr2: any[]) => {
  if (arr1?.length !== arr2?.length) {
    return false
  }

  const newArr1 = arr1.slice()
  const newArr2 = arr2.slice()

  newArr1.sort()
  newArr2.sort()

  return newArr1.every((item, index) => item === newArr2[index])
}
