import { LocalStorageKeys } from '~/enums/localStorageKeys'
import useLocalStorage from '~/hooks/useLocalStorage'

interface IIFrameParams {
  support?: boolean
  redirectUrl?: string
  hideBoletoAsUpfront?: boolean
}

export const useIFrameParams = () => {
  const [iframeParams, setIframeParams] = useLocalStorage<IIFrameParams>(LocalStorageKeys.iframeParams, {})

  return { iframeParams, setIframeParams }
}
