import axios from 'axios'
import { ROOT_URL } from '~/constants/index'
import { LocalStorageKeys } from '~/enums/localStorageKeys'
import { errorHandler } from '~/utils/errorHandler'
import { generateToken } from '~/utils/generateToken'

const api = axios.create({
  baseURL: ROOT_URL?.MS_PROVI_PAY || '',
  headers: {
    Authorization: generateToken(),
  },
})

// creditPathApi settings are available at 'src/contexts/ProgressContext.tsx'
const creditPathApi = axios.create({
  baseURL: ROOT_URL?.MS_PROVI_PAY || '',
})

const msInvoiceApi = axios.create({
  baseURL: ROOT_URL?.MS_INVOICE || '',
  headers: {
    Authorization: `${ROOT_URL?.MS_INVOICE_API_KEY}`, // Replace with your token or key
    'Content-Type': 'application/json', // Set any other default headers if needed
  },
})

// api settings are available at 'src/contexts/ProgressContext.tsx'\
// but since it's inside a context, it can only be called in the client side
// this will only be run on the server side
if (typeof window === 'undefined') {
  api.interceptors.request.use(
    (config) => {
      const token = generateToken()
      if (token) {
        config.headers.Authorization = token
      }

      return config
    },
    (error) => {
      return errorHandler(error.response.data)
    },
  )

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error?.response ? error?.response?.status : null

      if (status === 401) {
        const newToken = generateToken()
        error.config.headers.Authorization = newToken
        localStorage.setItem(LocalStorageKeys.token, newToken)
        return axios.request(error.config)
      }

      return errorHandler(error.response.data)
    },
  )
}

export { api, creditPathApi, msInvoiceApi }
