export const gtagToFbqDictionary = (action: Gtag.EventNames) => {
  switch (action) {
    case 'purchase':
      return { event: 'Purchase', isCustom: false }
    case 'add_payment_info':
      return { event: 'AddPaymentInfo', isCustom: false }
    case 'add_to_cart':
      return { event: 'AddToCart', isCustom: false }
    case 'begin_checkout':
      return { event: 'InitiateCheckout', isCustom: false }
    case 'generate_lead':
      return { event: 'Lead', isCustom: false }
    default:
      return {
        event: action
          .split('_')
          .map((word) => word[0].toUpperCase() + word.slice(1))
          .join(''),
        isCustom: true,
      }
  }
}
