import { IGoogleEvent } from '~/types/index'
import { gtagToFbqDictionary } from './gtagToFbqDictionary'
/**
 * @name GTAG_TRACKING_ID
 * @description
 * The Google Tag Manager tracking ID.
 */
export const GTAG_TRACKING_ID = process.env.NEXT_PUBLIC_GTAG_ID as string
const isProduction = process.env.NEXT_PUBLIC_API_ENV === 'production'

export const initEvents = (ids: string[]) => {
  if (ids?.length === 0) {
    return
  }

  ids.forEach((id) => {
    window?.fbq?.('init', id)
    console.log(`[Facebook Pixel] init ${id}`)
  })
}

/**
 * @name pageview
 * @description Sends a pageview to Google Analytics
 * @param {string} url - The URL of the pageview
 * @example
 * pageview('/about')
 * @returns {void}
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 */
export const pageview = (url: string): void => {
  if (!isProduction) {
    return
  }

  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'page_view',
      eventModel: {
        page_title: window.document.title,
        page_location: window.location.href,
        page_path: url,
        send_to: GTAG_TRACKING_ID,
      },
    })
  }
}

/**
 * @name event
 * @description Sends an event to Google Analytics
 * @param {IGoogleEvent} event - The event to send
 * @example
 * event({
 *   action: 'click',
 *   eventParams: {
 *     event_category: 'button',
 *     event_label: 'button label',
 *   }
 * })
 * @returns {void}
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
export const event = ({ action, eventParams }: IGoogleEvent): void => {
  if (!isProduction) {
    return
  }

  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: action,
      eventModel: eventParams,
    })
  }

  if (window && window?.clarity) {
    window?.clarity('event', action, JSON.stringify(eventParams, null, 2))
  }

  if (window && window.fbq) {
    const fbqEvent = gtagToFbqDictionary(action)
    const fbqEventType = fbqEvent.isCustom ? 'trackCustom' : 'track'

    if (fbqEvent) {
      window?.fbq?.(fbqEventType, fbqEvent.event, eventParams)
    }
  }
}
