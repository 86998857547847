import { useContext, useEffect } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { initEvents } from '~/utils/gtag'
import { pixels } from './pixels'

export const useAnalytics = () => {
  const { partnerId } = useContext(CheckoutContext)

  useEffect(() => {
    pixels?.[partnerId] && initEvents?.(pixels?.[partnerId])
  }, [partnerId])
}
