// future todo: get pixelIds from backend
export const pixels = {
  // 392: ['753897684968781'],
  886: ['292191068434729'],
  1395: ['277101517919264'],
  1400: ['404143806896864'],
  1531: ['1483451468704455'],
  1600: ['1394183194268375'],
  1829: ['378308237507232', '367764568579611', '767340390515545'],
  2321: ['376437239987818'],
  2350: ['1236748436724381', '7763560647040713'],
  2432: ['269962874140869'],
  2528: ['343882333001392'],
  2557: ['409256176379573'],
  2588: ['482195923768986'],
  2884: ['1394183194268375'],
  2616: ['158508206400120'],
  2640: ['427909261828672'],
  1332: ['436181040930209'],
  186: ['471433743521699'],
  2726: ['4469774306484594'],
  2334: ['3102812629831342'],
  2815: ['865587450598057'],
  2722: ['3067789346855458'],
  2795: ['1657009814473295'],
  2602: ['671170280346412'],
  2853: ['671170280346412'],
  2865: ['506405090816155'],
  2704: ['1814444352105163'],
  2912: ['616463396116346'],
  2617: ['1496240180785043'],
  2914: ['422324349068805'],
  1885: ['742295852994381'],
  2938: ['282782502762021'],
  328: ['425637885214458'],
  1309: ['876986592636775'],
  2960: ['964424394453082'],
  2977: ['867025870809015'],
  3007: ['377126500620272'],
  3075: ['3949587905267842'],
}
