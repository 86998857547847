interface LooseObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export const buildURLQuery = (obj: LooseObject) => {
  const query = Object.keys(obj || {})
    .map((key) => {
      const value = obj[key]
      if (Array.isArray(value)) {
        return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&')
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    })
    .join('&')
  return query.length ? `?${query}` : ''
}
