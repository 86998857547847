export const hiddenHeaderCheckoutScreens = [
  'basicInfoAnalysis',
  'checkoutFail',
  'checkoutSuccess',
  'guarantorDenied',
  'selfie',
  'insertCNH',
  'insertRGFront',
  'insertRGBack',
  'verificationScreen',
  'reviewInfo',
  'creditInAnalysis',
  'incompleteBasicInfoAnalysis',
  'incompleteGuarantorAnalysisTimeout',
  'incompleteDocumentAnalysisTimeout',
  'documentAnalysis',
  'basicInfoAnalysis',
  'existingRequests',
  'invalidRequest',
  'courses',
  'incompleteCCB',
  'financingSuccess',
  'waitingGuarantorSignature',
  'startReview',
  'endReview',
  'introISA',
  'invalidPath',
  'loadingCPFAnalysis',
]
