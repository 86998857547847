import { theme } from '@provi/provi-components'
import { ParamsProvider } from 'contexts/ParamsContext'
import moment from 'moment'
import 'moment/locale/pt-br'
import { NextSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/dist/client/router'
import Head from 'next/head'
import NextNProgress from 'nextjs-progressbar'
import { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyles from 'styles/global'
import { CheckoutProvider } from '~/contexts/CheckoutContext'
import { CoursesProvider } from '~/contexts/CoursesContext'
import { CreditPathProvider } from '~/contexts/CreditPathContext'
import Interceptors from '~/contexts/Interceptors'
import { pageview } from '~/utils/gtag'
import '../styles/tailwind.css'

function App({ Component, pageProps }: AppProps) {
  moment.locale('pt-br')
  const [oldPage, setOldPage] = useState(typeof window !== 'undefined' ? window.location.pathname : '')

  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const urlWithoutQueryParams = url.split('?')[0]
      if (oldPage !== urlWithoutQueryParams) {
        pageview(urlWithoutQueryParams)
      }
      setOldPage(urlWithoutQueryParams)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, oldPage])

  useEffect(() => {
    // Send history change event to gtag on initilization
    pageview(router.asPath)

    // eslint-disable-next-line
  }, [])

  const seoDescription =
    'Acreditamos que as pessoas podem fazer coisas incríveis se tiverem o incentivo certo no momento correto. Alavanque sua carreira. Conte com a PrincipiaPay!'
  const seoImage = '/og_logo_principiapay.png'

  return (
    <>
      <Head>
        <title>PrincipiaPay</title>
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
        <NextSeo
          title={'PrincipiaPay'}
          description={seoDescription}
          openGraph={{
            title: 'PrincipiaPay',
            description: seoDescription,
            locale: 'pt_BR',
            site_name: 'PrincipiaPay',
            url: `https://pay.principia.net${router.asPath}`,
            type: 'website',
            images: [
              {
                url: seoImage,
                alt: 'PrincipiaPay logo',
              },
            ],
          }}
        />
      </Head>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <GlobalStyles />
      <NextNProgress
        color={theme.colors.bluePrimary}
        startPosition={0.3}
        stopDelayMs={200}
        height={3}
        showOnShallow={false}
        options={{
          showSpinner: false,
        }}
      />
      <div id="modal-root" style={{ position: 'relative', zIndex: 999 }}></div>
      <CoursesProvider>
        <ParamsProvider>
          <CheckoutProvider>
            <Interceptors />
            <CreditPathProvider>
              <Component {...pageProps} />
            </CreditPathProvider>
          </CheckoutProvider>
        </ParamsProvider>
      </CoursesProvider>
    </>
  )
}

export default App
