import { event } from '.'
import { IItem } from '~/types/index'

export const gtagAddToCart = (value?: string | number | null, item?: IItem) => {
  event({
    action: 'add_to_cart',
    eventParams: {
      value: Number(value),
      currency: 'BRL',
      items: [
        {
          CourseClassId: item?.CourseClassId,
          CourseId: item?.CourseId,
          className: item?.className,
          courseName: item?.courseName,
          price: item?.price,
          quantity: 1,
        },
      ],
    },
  })
}
