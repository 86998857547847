import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

if (
  process.env.NEXT_PUBLIC_API_ENV === 'production' &&
  process.env.NODE_ENV !== 'development' &&
  process.env.NODE_ENV !== 'test'
) {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://dff25965bf2b450e9fd64d6e98c76f96@o245612.ingest.sentry.io/5552362',
    tracesSampleRate: 1.0,
  })
}
