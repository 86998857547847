/**
 *
 * @param currentPath the current asPath the user is on, eg /stuff/example/more-stuff?utm-campaing=test
 * @param newPath the new path we want to push to the router, eg /stuff/example/even-more-stuff
 * @returns the newPath with the query params from the existing path, eg /stuff/example/even-more-stuff?utm-campaing=test
 */
export const changeRouteKeepParams = (currentPath: string, newPath: string): string => {
  const hasQueries = currentPath?.indexOf('?')
  const searchQuery = hasQueries >= 0 ? currentPath.substring(hasQueries) : ''
  const params = new URLSearchParams(searchQuery)
  const paramsStr = params.toString() !== '' ? `?${params.toString()}` : ''

  return `${newPath}${paramsStr}`
}
