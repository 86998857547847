import {
  FormScreenNTypes,
  ICartData,
  ICartParams,
  ICheckoutFailData,
  ICheckoutSuccessData,
  IContinueFromPhone,
  IContractInfo,
  ICourseFinancingPayment,
  ICourseParams,
  ICoursesData,
  ICreateLeadFromCourse,
  ICreateLeadFromCourseBody,
  ICreditCardPayment,
  ICurrentScreen,
  IEndBasicInfoResponse,
  IExclusiveSale,
  IExistingRequestsResponse,
  IGenerateOTP,
  IGenerateOTPResponse,
  IGetCart,
  IInvalidPathResponse,
  IInvoiceData,
  ILoadingAnalysis,
  ILoadingCPFAnalysisResponse,
  ILoadingFinanceSimulationResponse,
  INextScreen,
  IOTPInformations,
  IParamsAppointGuarantor,
  IPartnerData,
  IPaymentData,
  IPaymentSelectionRootObject,
  IPopulateInputsAddress,
  IReadAddressData,
  IReadCouponCode,
  IReadCreditApproved,
  IReadCreditDenied,
  IReadCreditInAnalysis,
  IReadFinancingSuccess,
  IReadGuarantorAnalysis,
  IReadGuarantorDenied,
  IReadInsertDocument,
  IReadIntroGuarantor,
  IReadSelfie,
  IReadSinglePageCheckout,
  IResponseFinancingSummary,
  IResponseGuarantorChecklist,
  IResponseIntroISA,
  IResponseIsaSelectProduct,
  IResponsePreApproved,
  IResponsePreDocument,
  IReviewBlockResponse,
  IReviewFlowResponse,
  IReviewInfo,
  ISendBasicInfoForm,
  ISendCreateRequest,
  ISignatureSummaryResponse,
  IStep,
  IUpdateCartResponse,
  IValidateOTP,
  IValidateOTPResponse,
  IWaitingGuarantorSignature,
  IWriteExistingRequests,
  IWriteGuarantorAnalysisResponse,
  IWriteIncompleteAnalysis,
  IWriteIncompleteGuarantorAnalysis,
  IWriteInsertDocument,
  IWriteInsertDocumentBody,
  IWriteIntroGuarantor,
  IWriteIntroGuarantorResponse,
  IWriteLoadingAnalysis,
  IWriteLoadingFinanceSimulation,
  IWritePreDocument,
  IWriteSelfie,
  IWriteSelfieResponse,
  IWriteSinglePageCheckout,
  IWriteSinglePageCheckoutData,
  IWriteUpfrontCreditCardPayment,
  ResponseNextScreen,
  WriteIsaSelectProduct,
} from 'types'
import { LocalStorageKeys } from '~/enums/localStorageKeys'
import { api, creditPathApi, msInvoiceApi } from './config'

export const getPartner = async (partnerSlug: string): Promise<IPartnerData> => {
  try {
    const { data } = await api.get(`/v3/partner/${partnerSlug}`)

    return data
  } catch (error) {
    return error
  }
}

export const getExclusiveSale = async (saleId: string): Promise<IExclusiveSale> => {
  if (!saleId) {
    return
  }

  try {
    const { data } = await api.get(`/v3/checkout/exclusive-sale/${saleId}`)

    return data
  } catch (error) {
    return error
  }
}

export const getCourses = async (params: ICourseParams): Promise<ICoursesData | string> => {
  try {
    const requestParams: ICourseParams = {}
    const url = `/v3/courses-and-classes/${params?.slug}`

    if (params?.campaignSlug) {
      requestParams.campaignSlug = params.campaignSlug
    }
    if (params?.token) {
      requestParams.token = params.token
    }
    if (params?.limit) {
      requestParams.limit = params.limit
    }
    if (params?.offset) {
      requestParams.offset = params.offset
    }
    if (params?.courseName) {
      requestParams.courseName = params.courseName
    }

    const response = await api.get(url, {
      params: requestParams,
    })

    return response.data
  } catch (error) {
    return error
  }
}

export const readBasicInfo = async () => {
  try {
    const response = await creditPathApi.get(`/v1/credit-path/screen/basicInfo`)
    return response.data
  } catch (error) {
    return error
  }
}

export const createCreditRequest = async (params: ISendCreateRequest): Promise<ResponseNextScreen | string> => {
  try {
    const requestParams = {} as ISendCreateRequest

    if (params?.CPF) {
      requestParams.CPF = params.CPF
    }
    if (params?.PartnerId) {
      requestParams.PartnerId = params.PartnerId
    }
    if (params?.classArray && params.classArray[0] !== null) {
      requestParams.classArray = params.classArray
    }
    if (params?.token) {
      requestParams.token = params.token
    }
    if (params?.campaignSlug) {
      requestParams.campaignSlug = params.campaignSlug
    }
    if (params?.SellerId) {
      requestParams.SellerId = params.SellerId
    }
    if (params?.type) {
      requestParams.type = params.type
    }

    const response = await api.post(`/v3/checkout/create-cr`, requestParams)
    return response.data
  } catch (error) {
    return error
  }
}

export const createBasicInfo = async (params: ISendBasicInfoForm): Promise<ResponseNextScreen | string> => {
  if (params?.RG === '') {
    delete params.RG
  }

  if (params?.birthDate === '') {
    delete params.birthDate
  }

  if (params?.fullSocialName === '') {
    delete params.fullSocialName
  }

  try {
    const response = await creditPathApi.post(`/v1/credit-path`, {
      currentScreen: 'basicInfo',
      data: params,
    })

    return response.data
  } catch (error) {
    return error
  }
}

export const readAddressInfo = async (): Promise<IReadAddressData | string> => {
  try {
    const { data } = await creditPathApi.get(`/v1/credit-path/screen/address`)

    return data
  } catch (error) {
    return error
  }
}

export const createAddressInfo = async (params: IPopulateInputsAddress): Promise<ResponseNextScreen | string> => {
  try {
    const body = {
      currentScreen: 'address',
      data: params,
    }
    const response = await creditPathApi.post(`/v1/credit-path`, body)

    return response.data
  } catch (error) {
    return error
  }
}

export const getCart = async ({ partnerSlug, params }: IGetCart): Promise<ICartData> => {
  // wait 100ms for sessionStorage to finish saving token (create-cr gets a new token, save it in localStorage then we update cart again)
  await new Promise((resolve) => setTimeout(resolve, 200))

  const requestParams: ICartParams = {}

  if (params?.token) {
    requestParams.token = params.token
  }

  if (params?.classes?.length) {
    requestParams.courseClass = `[${params.classes}]`
  }

  if (params?.campaign) {
    requestParams.campaignSlug = params.campaign
  }

  const isInCourses = window.location.pathname.includes('/courses')
  const userToken = sessionStorage.getItem(LocalStorageKeys.user_token)

  const { data } = await api.get(`/v3/cart/${partnerSlug}`, {
    params: isInCourses || !userToken ? requestParams : { token: userToken },
  })

  return data
}

export const updateCart = async (classArray: number[]): Promise<IUpdateCartResponse | string> => {
  // wait to get token from sessionStorage
  await new Promise((resolve) => setTimeout(resolve, 200))

  try {
    const { data } = await creditPathApi.post(`/v3/cart/update-cart`, {
      classArray,
    })

    return data
  } catch (error) {
    return error
  }
}

export const readPaymentInfo = async (): Promise<IPaymentData | string> => {
  // wait 200ms for updateCart to finish
  await new Promise((resolve) => setTimeout(resolve, 200))

  try {
    const paymentResponse = await creditPathApi.get<IPaymentData>(`/v1/credit-path/screen/paymentSelection`)

    return paymentResponse.data
  } catch (error) {
    return error
  }
}

export const readCheckoutSuccess = async (): Promise<ICheckoutSuccessData | string> => {
  try {
    const { data } = await creditPathApi.get(`/v1/credit-path/screen/checkoutSuccess`)

    return data
  } catch (error) {
    return error
  }
}

export const createPayment = async (
  params: ICreditCardPayment | ICourseFinancingPayment,
): Promise<ResponseNextScreen | string> => {
  try {
    const response = await creditPathApi.post(`/v1/credit-path`, params)

    return response.data
  } catch (error) {
    return error
  }
}

export const readCheckoutFail = async (): Promise<ICheckoutFailData | string> => {
  try {
    const { data } = await creditPathApi.get(`/v1/credit-path/screen/checkoutFail`)

    return data
  } catch (error) {
    return error
  }
}

export const writeCheckoutStatus = async (params: IPaymentSelectionRootObject): Promise<ResponseNextScreen | string> => {
  try {
    const { data } = await creditPathApi.post(`/v1/credit-path`, params)

    return data
  } catch (error) {
    return error
  }
}

export const readReviewInfo = async () => {
  try {
    const response = await creditPathApi.get('/v1/credit-path/screen/reviewInfo')

    return response.data
  } catch (error) {
    return error
  }
}

export const writeReviewInfo = async (params: IReviewInfo): Promise<ResponseNextScreen | string> => {
  try {
    const { data } = await creditPathApi.post(`/v1/credit-path`, params)

    return data
  } catch (error) {
    return error
  }
}

export const generateOTP = async ({ validationType }: IGenerateOTP): Promise<IGenerateOTPResponse | string> => {
  try {
    const response = await creditPathApi.post(`/v3/checkout/generate-otp`, { validationType })
    return response.data
  } catch (error) {
    return error
  }
}

export const getOTPInformations = async (screen: string): Promise<IOTPInformations | string> => {
  try {
    const response = await creditPathApi.get(`/v1/credit-path/screen/${screen}`)
    return response.data
  } catch (error) {
    return error
  }
}

export const readGuarantorChecklist = async (): Promise<IResponseGuarantorChecklist | string> => {
  try {
    const response = await creditPathApi.get('/v1/credit-path/screen/guarantorChecklist')

    return response.data
  } catch (error) {
    return error
  }
}

export const writeGuarantorChecklist = async (currentScreen: ICurrentScreen): Promise<INextScreen | string> => {
  try {
    const response = await creditPathApi.post('/v1/credit-path', currentScreen)

    return response.data
  } catch (error) {
    return error
  }
}

/**
 * @param {string} currentScreen - The current screen (step)
 * @param {string} code - The OTP code to validate
 * @param {'email' | 'phone'} validationType - The validation type (email or phone)
 * @param {string} newIdentification - Change to new identification (new email or new phone)
 */
export const validateOTP = async ({
  currentScreen,
  code,
  validationType,
  newIdentification,
}: IValidateOTP): Promise<IValidateOTPResponse | string> => {
  try {
    if (currentScreen === 'checkoutEmailVerification') {
      const response = await creditPathApi.post(`/v3/checkout/${validationType}/validate-otp`, {
        token: code,
      })
      return response.data
    } else {
      // this variable can take either a newEmail or newPhone value
      let changeIdentificationData = {}
      const defaultData = {
        code,
      }

      // this is to change either the email or the phone from the user
      if (newIdentification) {
        if (validationType === 'email') {
          changeIdentificationData = {
            newEmail: newIdentification,
          }
        } else {
          changeIdentificationData = {
            newPhone: newIdentification,
          }
        }
      }

      const data = newIdentification ? changeIdentificationData : defaultData

      const response = await creditPathApi.post(`/v1/credit-path`, {
        currentScreen,
        data,
      })
      return response.data
    }
  } catch (error) {
    return error
  }
}

export const readGuarantorAnalysis = async (): Promise<IReadGuarantorAnalysis | string> => {
  try {
    const response = await creditPathApi.get<IReadGuarantorAnalysis>('/v1/credit-path/screen/guarantorAnalysis')
    return response.data
  } catch (error) {
    return error
  }
}

export const readIntroGuarantor = async (): Promise<IReadIntroGuarantor | string> => {
  try {
    const { data } = await creditPathApi.get(`/v1/credit-path/screen/introGuarantor`)
    return data
  } catch (error) {
    return error
  }
}

export const readAppointGuarantor = async (): Promise<IResponseGuarantorChecklist | string> => {
  try {
    const response = await creditPathApi.get('/v1/credit-path/screen/appointGuarantor')

    return response.data
  } catch (error) {
    return error
  }
}

export const writeAppointGuarantor = async (params: IParamsAppointGuarantor): Promise<INextScreen | string> => {
  try {
    const response = await creditPathApi.post('/v1/credit-path', params)

    return response.data
  } catch (error) {
    return error
  }
}

export const writeIntroGuarantor = async (params: IWriteIntroGuarantor): Promise<IWriteIntroGuarantorResponse | string> => {
  try {
    const { data } = await creditPathApi.post(`/v1/credit-path`, params)
    return data
  } catch (error) {
    return error
  }
}

export const readPreSelfie = async (): Promise<IResponseGuarantorChecklist | string> => {
  try {
    const response = await creditPathApi.get('/v1/credit-path/screen/preSelfie')

    return response.data
  } catch (error) {
    return error
  }
}

export const writePreSelfie = async (params: ICurrentScreen): Promise<INextScreen | string> => {
  try {
    const { data } = await creditPathApi.post(`/v1/credit-path`, params)
    return data
  } catch (error) {
    return error
  }
}

export const readPreApproved = async (): Promise<IResponsePreApproved | string> => {
  try {
    const response = await creditPathApi.get('/v1/credit-path/screen/preApproved')

    return response.data
  } catch (error) {
    return error
  }
}

export const writeGuarantorAnalysis = async (currentScreen: IStep): Promise<IWriteGuarantorAnalysisResponse | string> => {
  try {
    const { data } = await creditPathApi.post(`/v1/credit-path`, {
      currentScreen,
    })
    return data
  } catch (error) {
    return error
  }
}

export const writePreApproved = async (params: ICurrentScreen): Promise<INextScreen | string> => {
  try {
    const { data } = await creditPathApi.post(`/v1/credit-path`, params)

    return data
  } catch (error) {
    return error
  }
}

export const readPreDocument = async (): Promise<IResponsePreDocument | string> => {
  try {
    const response = await creditPathApi.get('/v1/credit-path/screen/preDocument')

    return response.data
  } catch (error) {
    return error
  }
}

export const writePreDocument = async (params: IWritePreDocument): Promise<INextScreen | string> => {
  try {
    const { data } = await creditPathApi.post(`/v1/credit-path`, params)
    return data
  } catch (error) {
    return error
  }
}

export const readLoadingAnalysis = async (currentScreen: IStep): Promise<ILoadingAnalysis | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/${currentScreen}`)
    return data
  } catch (error) {
    return error
  }
}

export const writeLoadingAnalysis = async (currentScreen: IStep): Promise<IWriteLoadingAnalysis | string> => {
  try {
    const { data } = await creditPathApi.post('v1/credit-path', { currentScreen })

    return data
  } catch (error) {
    return error
  }
}

export const readCreditInAnalysis = async (): Promise<IReadCreditInAnalysis | string> => {
  try {
    const { data } = await creditPathApi.get('v1/credit-path/screen/creditInAnalysis')
    return data
  } catch (error) {
    return error
  }
}

export const readFinancingSummary = async (): Promise<IResponseFinancingSummary | string> => {
  try {
    const { data } = await creditPathApi.get('v1/credit-path/screen/financingSummary')
    return data
  } catch (error) {
    return error
  }
}

export const writeCreditApproved = async (currentScreen: IStep): Promise<INextScreen | string> => {
  try {
    const { data } = await creditPathApi.post('v1/credit-path', { currentScreen })

    return data
  } catch (error) {
    return error
  }
}

export const readSelfie = async (): Promise<IReadSelfie | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/selfie?q=${Math.random()}`)
    return data
  } catch (error) {
    return error
  }
}

export const writeSelfie = async ({
  currentScreen,
  data: informations,
}: IWriteSelfie): Promise<IWriteSelfieResponse | string> => {
  try {
    const { data } = await creditPathApi.post('v1/credit-path', { currentScreen, data: informations })

    return data
  } catch (error) {
    return error
  }
}

export const readGuarantorDenied = async (): Promise<IReadGuarantorDenied | string> => {
  try {
    const { data } = await creditPathApi.get('v1/credit-path/screen/guarantorDenied')
    return data
  } catch (error) {
    return error
  }
}

export const readInsertDocument = async (step: IStep): Promise<IReadInsertDocument | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/${step}`)
    return data
  } catch (error) {
    return error
  }
}

export const writeFinancingSummary = async (currentScreen: IStep): Promise<INextScreen | string> => {
  try {
    const { data } = await creditPathApi.post('v1/credit-path', { currentScreen })
    return data
  } catch (error) {
    return error
  }
}

export const readCreditApproved = async (): Promise<IReadCreditApproved | string> => {
  try {
    const { data } = await creditPathApi.get('v1/credit-path/screen/creditApproved')

    return data
  } catch (error) {
    return error
  }
}

export const writeInsertDocument = async (body: IWriteInsertDocumentBody): Promise<IWriteInsertDocument | string> => {
  try {
    const { data } = await creditPathApi.post('v1/credit-path', body)

    return data
  } catch (error) {
    return error
  }
}

export const readContractSummary = async (step: IStep): Promise<IContractInfo | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/${step}`)
    return data
  } catch (error) {
    return error
  }
}

export const createLeadFromCourse = async (body: ICreateLeadFromCourseBody): Promise<ICreateLeadFromCourse | string> => {
  try {
    const { data } = await api.post('v3/courses-and-classes/course-leads', body)

    return {
      ...data,
      success: true,
    }
  } catch (error) {
    return error
  }
}

export const readFinancingSuccess = async (step: IStep): Promise<IReadFinancingSuccess | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/${step}`)
    return data
  } catch (error) {
    return error
  }
}

export const writeContractSummary = async (currentScreen: IStep): Promise<INextScreen | string> => {
  try {
    const { data } = await creditPathApi.post('v1/credit-path', { currentScreen })

    return data
  } catch (error) {
    return error
  }
}

export const writeIncompleteAnalysis = async (
  endpoint: 'incomplete-basic-info-analysis' | 'incomplete-document-analysis' | 'incomplete-finance-simulation',
): Promise<IWriteIncompleteAnalysis | string> => {
  try {
    const { data } = await creditPathApi.post(`v1/credit-path/${endpoint}`)

    return data
  } catch (error) {
    return error
  }
}

export const readWaitingGuarantorSignature = async (step: IStep): Promise<IWaitingGuarantorSignature | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/${step}`)

    return data
  } catch (error) {
    return error
  }
}

export const writeExistingRequests = async (
  creditRequestId: IWriteExistingRequests,
): Promise<IExistingRequestsResponse | string> => {
  try {
    const { data } = await creditPathApi.post(`v3/checkout/continue-request`, { CreditRequestId: creditRequestId })

    return data
  } catch (error) {
    return error
  }
}

export const writeIncompleteCCB = async () => {
  try {
    const { data } = await creditPathApi.post('/v1/credit-path/incomplete-ccb')
    return data
  } catch (error) {
    return error
  }
}

export const sendContinueFromPhone = async (): Promise<IContinueFromPhone | string> => {
  try {
    const { data } = await creditPathApi.post(`v1/credit-path/credit-path-url`)

    return data
  } catch (error) {
    return error
  }
}

export const readEndBasicInfo = async (step: IStep): Promise<IEndBasicInfoResponse | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/${step}`)

    return data
  } catch (error) {
    return error
  }
}

export const writeEndBasicInfo = async (currentScreen: IStep): Promise<INextScreen | string> => {
  try {
    const { data } = await creditPathApi.post(`v1/credit-path`, { currentScreen })

    return data
  } catch (error) {
    return error
  }
}

export const readReviewFlow = async (step: IStep): Promise<IReviewFlowResponse | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/${step}`)

    return data
  } catch (error) {
    return error
  }
}

export const writeReviewFlow = async (currentScreen: IStep): Promise<IReviewBlockResponse | string> => {
  try {
    const { data } = await creditPathApi.post(`v1/credit-path`, { currentScreen })

    return data
  } catch (error) {
    return error
  }
}

export const writeIncompleteGuarantorAnalysis = async (): Promise<IWriteIncompleteGuarantorAnalysis> => {
  try {
    const { data } = await creditPathApi.post('v1/credit-path/incomplete-guarantor-analysis')

    return data
  } catch (error) {
    return error
  }
}

export const readCouponCode = async (currentScreen: IStep): Promise<IReadCouponCode | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/${currentScreen}`)

    return data
  } catch (error) {
    return error
  }
}

export const readFormScreenN = async (step: IStep): Promise<FormScreenNTypes.IQuestionResponse | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/${step}`)

    return data
  } catch (error) {
    return error
  }
}

export const readIsaSelectProduct = async ({
  offset,
  limit,
  courseName,
}: {
  offset: number
  limit: number
  courseName?: string
}): Promise<IResponseIsaSelectProduct | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/selectProduct`, {
      params: {
        offset,
        limit,
        ...(courseName && { courseName }),
      },
    })

    return data
  } catch (error) {
    return error
  }
}

export const writeIntroIsa = async (currentScreen: IStep): Promise<INextScreen | string> => {
  try {
    const { data } = await creditPathApi.post('v1/credit-path', { currentScreen })

    return data
  } catch (error) {
    return error
  }
}

export const writeCouponCode = async (currentScreen: IStep, code: string): Promise<INextScreen | string> => {
  try {
    const { data } = await creditPathApi.post('v1/credit-path', { currentScreen, data: { code } })

    return data
  } catch (error) {
    return error
  }
}

export const writeFormScreenN = async (
  currentScreen: IStep,
  answers: FormScreenNTypes.IAnswers,
): Promise<INextScreen | string> => {
  try {
    const { data } = await creditPathApi.post(`v1/credit-path`, {
      currentScreen,
      data: {
        ...answers,
      },
    })

    return data
  } catch (error) {
    return error
  }
}

export const readIntroIsa = async (): Promise<IResponseIntroISA | string> => {
  try {
    const { data } = await creditPathApi.get('/v1/credit-path/screen/introISA')

    return data
  } catch (error) {
    return error
  }
}

export const writeIsaSelectProduct = async (params: WriteIsaSelectProduct) => {
  try {
    const { data } = await creditPathApi.post(`v1/credit-path`, params)
    return data
  } catch (error) {
    return error
  }
}

export const readInvalidPath = async (): Promise<IInvalidPathResponse | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/invalidPath`)
    return data
  } catch (error) {
    return error
  }
}

export const readLoadingCPFAnalysis = async (): Promise<ILoadingCPFAnalysisResponse | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/loadingCPFAnalysis`)
    return data
  } catch (error) {
    return error
  }
}

export const writeLoadingCPFAnalysis = async (currentScreen: IStep): Promise<INextScreen | string> => {
  try {
    const { data } = await creditPathApi.post(`v1/credit-path`, { currentScreen })
    return data
  } catch (error) {
    return error
  }
}

export const readLoadingFinanceSimulation = async (): Promise<ILoadingFinanceSimulationResponse | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/loadingFinanceSimulation`)
    return data
  } catch (error) {
    return error
  }
}

export const writeLoadingFinanceSimulation = async (currentScreen: IStep): Promise<IWriteLoadingFinanceSimulation | string> => {
  try {
    const { data } = await creditPathApi.post(`v1/credit-path`, { currentScreen })
    return data
  } catch (error) {
    return error
  }
}

export const readSinglePageCheckoutInfo = async (): Promise<IReadSinglePageCheckout | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/singlePageCheckout`)

    return data
  } catch (error) {
    return error
  }
}

export const writeSinglePageCheckout = async (
  params: IWriteSinglePageCheckoutData,
): Promise<IWriteSinglePageCheckout | string> => {
  try {
    const { data } = await creditPathApi.post(`v1/credit-path`, {
      currentScreen: 'singlePageCheckout',
      data: params,
    })

    return data
  } catch (error) {
    return error
  }
}

export const readCreditDenied = async (): Promise<IReadCreditDenied | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/creditDenied`)

    return data
  } catch (error) {
    return error
  }
}

export const writeCreditDenied = async (): Promise<INextScreen | string> => {
  try {
    const { data } = await creditPathApi.post(`v1/credit-path`, {
      currentScreen: 'creditDenied',
      data: {
        selectNewPaymentMethod: true,
      },
    })

    return data
  } catch (error) {
    return error
  }
}

export const getInvoiceData = async (invoiceId: string): Promise<IInvoiceData> => {
  try {
    const { data } = await msInvoiceApi.get(`/v1/iugu/${invoiceId}`)
    return data
  } catch (error) {
    return error
  }
}

export const writeUpfrontCreditCardPayment = async ({
  iuguId,
  creditCardHash,
  installmentsToApply,
}: IWriteUpfrontCreditCardPayment): Promise<any> => {
  try {
    const { data } = await msInvoiceApi.post(`/v1/payment/create-upfront-payment`, {
      iuguId,
      creditCardHash,
      installmentsToApply: installmentsToApply || 1,
    })
    return data
  } catch (error) {
    return error
  }
}

export const readSignatureSummary = async (): Promise<ISignatureSummaryResponse | string> => {
  try {
    const { data } = await creditPathApi.get(`v1/credit-path/screen/signatureSummary`)

    return data
  } catch (error) {
    return error
  }
}

export const writeSignatureSummary = async (): Promise<INextScreen | string> => {
  try {
    const { data } = await creditPathApi.post(`v1/credit-path`, {
      currentScreen: 'signatureSummary',
    })

    return data
  } catch (error) {
    return error
  }
}
